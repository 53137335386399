<template>
    <el-tooltip v-if="$store.getters.getRole() == 0" slot="reference" effect="dark" :content="$t('语音识别')"
        placement="top-start">
        <el-image class="margin-x" @click="showVip()" src="/web/image/word-ai.png" fit="cover"
            style="width:20px;"></el-image>
    </el-tooltip>
    <el-popover v-else style="cursor: pointer;margin-left: 10px;" placement="top">
        <div style="width: 300px;">
            <div v-loading="loading">
                <div v-if="result.pronunciation" class="st1 flex flex-between flex-v-center">
                    <div>{{ result.pronunciation > 80 ? $t("发音正确，请继续保持") : result.pronunciation > 60 ?
                        $t("发音有点问题，稍作改变会更完美")
                        : result.pronunciation > 0 ? $t("发音糟糕，请听正确发音并纠正") : $t("没有读这个单词") }}</div> <i
                        class="el-icon-refresh color-primary pointer lt" @click="refresh()"></i>
                </div>
                <div v-else class="flex flex-v-center padding">
                    <div class="st1">{{$t('请在右方进行录音')}}</div>
                    <div class="flex1 margin-l"><el-progress :percentage="percentage" :show-text="false"></el-progress>
                    </div>
                    <i @click="record()" v-if="recording" class="margin-l lt color-primary el-icon-video-pause"></i>
                    <el-image @click="record()" class="margin-l pointer" v-else src="/web/image/word-ai.png" fit="cover"
                        style="width:20px;"></el-image>

                </div>
                <div class="line margin-ts"></div>
                <h3 class="margin-ts">{{ info.word }}</h3>
                <div class="st flex flex-v-center"><span style="margin-right: 10px;">{{$t('你的发音')}}:</span>[<span
                        v-for="(ph, index1) in result.phonemes"
                        :class="{ 'v0': ph.pronunciation < 60, 'v1': ph.pronunciation >= 60 && ph.pronunciation <= 80, 'v2': ph.pronunciation >= 80 }"
                        :key="index1">{{ ph.phoneme }}</span>]

                    <el-tooltip v-if="src" :content="$t('播放录音')" placement="top">
                        <i @click="playRecord()" :class="playing ? 'el-icon-video-pause' : 'el-icon-video-play'"
                            class="margin-l lt color-primary  pointer"></i>
                    </el-tooltip>
                </div>
                <div class="margin-ts">
                    <div class="st1 flex flex-v-center">[UK]<span style="margin:0 10px;">{{
                        info.phonetic[0]
                    }}</span>
                        <el-image @click="play(1, info.word)" class="margin-x"
                            style="width: 24px; height: 24px;cursor: pointer;" src="/web/image/playing.png"></el-image>
                    </div>
                    <div class="st1 flex flex-v-center">[US]<span style="margin:0 10px;">{{
                        info.phonetic[1] }}</span>
                        <el-image @click="play(2, info.word)" class="margin-x"
                            style="width: 24px; height: 24px;cursor: pointer;" src="/web/image/playing.png"></el-image>
                    </div>
                    <div class="st1">
                        <div v-for="(row, index) in info.explanation.split('\n')" :key="index">
                            {{ row }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-tooltip slot="reference" class="item" effect="dark" :content="$t('语音识别')" placement="top-start">
            <el-image src="/web/image/word-ai.png" fit="cover" style="width:20px;"></el-image>
        </el-tooltip>

        <audio ref="audio"></audio>
        <audio ref="audio1" :src="src" @play="onPlay" @pause="onPause"></audio>
    </el-popover>
</template>

<script>
import Recorder from 'js-audio-recorder';
export default {
    name: "WordPopover",
    data() {
        return {
            timeLimit: 10,
            time: 0,
            percentage: 0,
            loading: false,
            recorder: null,
            recording: false,
            timeJob: null,
            result: {},
            playing: false,
            src: null
        }
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
    },

    created() {
        this.recorder = new Recorder({
            sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
            sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
            numChannels: 1,                 // 声道，支持 1 或 2， 默认是1
            // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });
    },

    methods: {
        showVip() {
            this.$vip_alert("此功能", "")
        },

        onPlay() {
            this.playing = true;
        },

        onPause() {
            this.playing = false;
        },

        playRecord() {
            this.$refs.audio1.play();
        },

        refresh() {
            this.src = null;
            this.percentage = 0;
            this.result = {};
        },

        record() {
            if (this.loading) return;
            if (this.recording) {
                this.recorder.stop();
                this.recording = false;
                this.loading = true;
                let file = this.recorder.getWAVBlob();
                this.src = URL.createObjectURL(this.recorder.getWAVBlob());
                let formData = new FormData();
                formData.append("file", file);
                formData.append("word", this.info.word);
                this.$http.post("/api/word/pronunciation", formData, {
                    'Content-type': 'multipart/form-data'
                }).then((result) => {
                    this.result = result;
                    this.loading = false;
                });
            } else {
                this.recorder.start().then(() => {
                    this.src = null;
                    this.recording = true;
                    this.timer();
                })
            }
        },
        play(type, word) {
            let src = this.$host + "/api/word/audio/" + word + "/" + type
            this.$refs.audio.src = src;
            this.$refs.audio.play();
        },
        timer() {
            if (this.timeJob) {
                clearInterval(this.timeJob)
            }
            this.time = 0;
            this.timeJob = setInterval(() => {
                this.time++;
                this.percentage = parseInt(this.time / this.timeLimit * 100);
                if (this.time >= this.timeLimit || !this.recording) {
                    clearInterval(this.timeJob);
                    this.timerJob = null;
                    this.record();
                }
            }, 1000);
        },
    },
}
</script>
<style scoped>
.v0 {
    color: red;
}

.v1 {
    color: yellow;
}

.v2 {
    color: green;
}
</style>